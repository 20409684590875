<template>
  <div class="course-chat">
    <div class="chat-head">
      <div class="head-left">
        <el-image
          class="head-icon"
          :src="require(`../../../assets/image/course.png`)"
          fit="contain"
        ></el-image>
        <div class="head-title">职业规划课程体系</div>
      </div>
      <div class="head-right">
        <div class="myWork" @click="goWork">我的作业</div>
        <el-image
          class="backIcon"
          @click="sendExit"
          :src="require(`../../../assets/image/exit2.png`)"
          fit="contain"
        ></el-image>
      </div>
    </div>

    <div class="main-option">
      <div
        class="option-list"
        v-for="(chat, index) in stageGradeList"
        :key="chat.chat_stage_grade_id"
        @click="selectChat(index, chat.chat_stage_grade_id)"
        :class="{ active: selectedQuestionnaireClassifyIndex === index }"
      >
        {{ chat.stage_grade_name }}
      </div>
    </div>

    <div class="chat-main">
      <div
        class="chat-messages"
        v-for="course in courseList"
        :key="course.chat_stage_id"
      >
        <div class="messages-title">{{ course.chat_stage_name }}</div>
        <el-carousel
          height="90%"
          class="messages-data"
          :autoplay="false"
          indicator-position="outside"
          trigger="click"
          @change="(index) => handleCarouselChange(course.chat_stage_id, index)"
        >
          <el-carousel-item v-for="item in course.total" :key="item">
            <el-row :gutter="50">
              <el-col
                :span="6"
                v-for="stage in course.stage_course"
                :key="stage.chat_course_id"
              >
                <div class="stage-data" @click="gotoEdit(stage)">
                  <el-image
                    class="data-top"
                    :src="stage.chat_course_url"
                    fit="fit"
                  ></el-image>
                  <div class="data-bottom">
                    <div class="bottom-name">{{ stage.chat_course_name }}</div>
                    <div class="bottom-comment">
                      {{ stage.chat_course_comment }}
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getCourseList,
  getStageList,
  getStageGradeList,
} from "../../../request/api";

export default {
  data() {
    return {
      courseList: [],
      limit: 8,
      page: 1,
      stageGradeList: [],
      selectedQuestionnaireClassifyIndex: 0,
    };
  },
  methods: {
    goWork() {
      this.$router.push("classWork");
    },
    handleCarouselChange(chat_stage_id, index) {
      this.page = index + 1;
      this.getStage(chat_stage_id);
    },
    selectChat(index, chat_stage_grade_id) {
      this.selectedQuestionnaireClassifyIndex = index;
      this.getCourse(chat_stage_grade_id);
    },
    sendExit() {
      this.$emit("update-value");
    },
    gotoEdit(stage) {
      let href = "";
      switch (stage.type) {
        case 1:
          href =
            "https://ow365.cn/?i=25285&ssl=1&furl=" + stage.chat_course_content;
          window.open(href, "_blank");
          break;
        case 2:
          this.$router.push({
            path: "/courseContent",
            query: {
              course_id: stage.chat_course_id,
            },
          });
          break;
        default:
          this.$message.error("出错了！联系管理员");
      }
    },
    async getStage(chat_stage_id) {
      try {
        const res = await getStageList(this.limit, this.page, chat_stage_id);
        if (res.status !== 200 || res.data.code !== "1000") {
          this.$message.error(res.data.msg);
          return;
        }

        this.courseList.find((ele) => {
          if (ele.chat_stage_id === chat_stage_id) {
            ele.stage_course = res.data.data;
            return true;
          }
          return false;
        });
      } catch (error) {
        this.$message.error("请求发生错误：" + error.message);
      }
    },
    async getCourse(chat_stage_grade_id) {
      try {
        const res = await getCourseList(
          this.limit,
          this.page,
          chat_stage_grade_id
        );
        if (res.status !== 200 || res.data.code !== "1000") {
          this.$message.error(res.data.msg);
          return;
        }

        this.courseList = res.data.data;
      } catch (error) {
        this.$message.error("请求发生错误：" + error.message);
      }
    },
    async init() {
      try {
        const res = await getStageGradeList();
        if (res.status !== 200 || res.data.code !== "1000") {
          this.$message.error(res.data.msg);
          return;
        }

        this.stageGradeList = res.data.data;
        this.getCourse(this.stageGradeList[0].chat_stage_grade_id);
      } catch (error) {
        this.$message.error("请求发生错误：" + error.message);
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="less" scoped>
.course-chat {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .chat-head {
    height: 8%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .head-left {
      display: flex;
      align-items: center;
      .head-icon {
        width: 40px;
        margin-right: 6px;
      }
      .head-title {
        font-size: 14px;
        color: #7a7c7e;
      }
    }
    .head-right {
      display: flex;
    }
    .myWork {
      width: 90px;
      line-height: 26px;
      cursor: pointer;
      color: #2a6ee9;
      font-size: 16px;
    }
    .backIcon {
      padding-right: 1%;
      width: 70px;
      cursor: pointer;
    }
  }
  .main-option {
    display: flex;
    height: 40px;
    border-bottom: 1px solid #fff;
    margin-bottom: 20px;
    .option-list {
      cursor: pointer;
      padding: 10px 30px;
      font-size: 14px;
    }
    .active {
      background-color: #fff;
    }
  }
  .chat-main {
    flex: 1;
    overflow-y: auto;
    .chat-messages {
      height: 80%;
      display: flex;
      flex-direction: column;
      .messages-title {
        height: 8%;
        font-size: 20px;
        font-weight: bold;
        display: flex;
        align-items: center;
        color: #599bfd;
      }
      .messages-data {
        flex: 1;
        .el-row {
          height: 100%;
          .el-col {
            height: 50%;
            padding: 1%;
            .stage-data {
              height: 100%;
              border-radius: 12px;
              box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.1);
              display: flex;
              flex-direction: column;
              cursor: pointer;
              .data-top {
                height: 60%;
                background-color: #d4e5ff;
                border-top-left-radius: 12px;
                border-top-right-radius: 12px;
              }
              .data-bottom {
                flex: 1;
                background-color: #fff;
                border-bottom-left-radius: 12px;
                border-bottom-right-radius: 12px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                .bottom-name {
                  font-size: 20px;
                  font-weight: bold;
                  margin-bottom: 4px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
